import * as PIXI from 'pixi.js';

let type = 'WebGL';

if (!PIXI.utils.isWebGLSupported()) {
  type = 'canvas';
}

PIXI.utils.sayHello(type);

export const pixi = PIXI;

export default pixi;
