import { Sprite, Container } from 'pixi.js';
import pixi from '../../lib/pixi';
import { testApp } from '../index';
import StarCluster from './StarCluster';
import { debounce } from '../utils';

class Starfield extends Container {
  private _bgLayer: Sprite;
  private _layers: StarCluster[] = [];
  private _staticField: StarCluster;
  private _numLayers: number = 3;
  private _frameID: any;

  constructor() {
    super();
    this._bgLayer = generateBGSprite(window.innerWidth, window.innerHeight);
    this.addChild(this._bgLayer);
    this._staticField = new StarCluster();
    this._staticField.alpha = 0.5;
    this.addChildAt(this._staticField, 0);
    this.on('added', this.onAdded);
  }

  public redraw = () => {
    this._bgLayer.width = window.innerWidth;
    this._bgLayer.height = window.innerHeight;

    if (this._layers.length) {
      this._layers.forEach(layer => {
        this.removeChild(layer);
      });
      this._layers.length = 0;
    }

    if (this._staticField) {
      this._staticField = new StarCluster();
    }

    for (let i = 0; i < this._numLayers; i += 1) {
      const starfield = new StarCluster();
      starfield.rotateBy = 0.0003 - i * 0.0002;
      starfield.alpha = 0.4 + Math.random() * i / 100;
      starfield.pivot.x = starfield.width / 2;
      starfield.pivot.y = starfield.height / 2;
      starfield.x = this._bgLayer.width / 2;
      starfield.y = this._bgLayer.height / 2;
      this._layers[i] = starfield;
      this.addChild(starfield);
    }
  };

  public pause = () => {
    window.cancelAnimationFrame(this._frameID);
  };

  public resume = () => {
    this.renderize(performance.now());
  };

  private updateStars = () => {
    this._layers.forEach(layer => {
      layer.rotation += layer.rotateBy;
    });
  };

  /**
   * Render game loop with gsap
   * @param time
   */
  private renderize = (time: number) => {
    this.updateStars();
    testApp.ticker.update(time);
    testApp.renderer.render(testApp.stage);
    this._frameID = requestAnimationFrame(this.renderize);
  };

  private handleResize = () => {
    console.log('redraw shit');
    this.redraw();
  };

  private debouncedResize = debounce(this.handleResize);

  private handleRemoved = () => {
    window.removeEventListener('resize', this.debouncedResize);
    this.off('removed', this.handleRemoved);
  };

  private onAdded = () => {
    this.redraw();
    this.renderize(performance.now());
    this.on('removed', this.handleRemoved);
    window.addEventListener('resize', this.debouncedResize);
  };
}

export const generateBGSprite = (
  w: number,
  h: number,
  color: number = 0xff3300,
  a: number = 0,
  x: number = 0,
  y: number = 0
): Sprite => {
  const bg = new pixi.Graphics();
  bg.beginFill(color);
  bg.drawRect(x, y, w, h);
  bg.alpha = a;
  bg.endFill();
  const texture = testApp.renderer.generateTexture(
    bg,
    pixi.SCALE_MODES.LINEAR,
    1
  );
  return new pixi.Sprite(texture);
};

export const generateCircleSprite = (
  r: number,
  color: number = 0xff3300,
  a: number = 0,
  x: number = 0,
  y: number = 0
): Sprite => {
  const bg = new pixi.Graphics();
  bg.beginFill(color);
  bg.drawCircle(x, y, r);
  bg.alpha = a;
  bg.endFill();
  const texture = testApp.renderer.generateTexture(
    bg,
    pixi.SCALE_MODES.LINEAR,
    1
  );
  return new pixi.Sprite(texture);
};

export default Starfield;
