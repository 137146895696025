import pixi from '../../lib/pixi';
import gsap from '../../lib/gsap';
import { EventEmitter } from 'events';
import randomize from '../utils';

class Pokemitter extends EventEmitter {
  private _view: any;
  private _randomSeed: number = 3;
  private _randomKey: number = 2;
  private _randomInterval: number = 800;
  private _randomTimeout: number | undefined = undefined;
  private _emitter: any = undefined;
  private _zipDuration: number = 3.5;

  public resetParams = () => {
    this.verticalField = 60;
    this.randomInterval = 800;
    this.zipDuration = 3.5;
  };

  private _verticalField: number = 60;

  get verticalField() {
    return this._verticalField;
  }
  set verticalField(v: number) {
    this._verticalField = v;
  }

  private _dir: 'left' | 'right' = 'left';

  set zipDuration(v: number) {
    this._zipDuration = v;
  }

  get zipDuration() {
    return this._zipDuration;
  }

  get randomInterval() {
    return this._randomInterval;
  }

  set randomInterval(v: number) {
    this._randomInterval = v;
  }

  public onEmitterShot = () => {
    this.view.tint = 0xff3300;
  };

  constructor(
    texture: any,
    width: number,
    height: number,
    x: number = 0,
    y: number = 0,
    emitter: (x: number, y: number) => void,
    gameWidth: number = 400,
    gameHeight: number = 800
  ) {
    super();
    this._view = new pixi.AnimatedSprite(texture);
    this._view.animationSpeed = 0.3;
    this._view.width = width;
    this._view.height = height;
    this._view.pivot.x = width / 2;
    this._view.pivot.y = height / 2;
    this._view.loop = false;
    this._view.x = x;
    this._view.y = y;
    this._emitter = emitter;
    this._view.on('added', this.onAdded);
    this._view.onComplete = this.reset;
    this.onRandomTimeout = this._onRandomTimeout.bind(this);

    this._gameHeight = gameHeight;
    this._gameWidth = gameWidth;
  }

  public resetEmitter = () => {
    this.resetParams();
    this._view.animationSpeed = 0.3;
    this._view.y = -300;
    this._view.on('added', this.onAdded);
  };

  private _gameHeight: number;
  private _gameWidth: number;

  private _viewTween: any = undefined;
  public onRandomTimeout: any = undefined;

  private reset = () => {
    this._view.gotoAndStop(0);
  };

  private _eases: any = [
    'power3.inOut',
    'elastic.inOut',
    'power4.inOut',
    'bounce.in',
    'power2.in',
  ];

  private toggleDir = () => {
    if (this._dir === 'left') {
      this._dir = 'right';
    } else {
      this._dir = 'left';
    }
  };

  private slideTo = () => {
    const x = this._dir === 'left' ? this._gameWidth - 80 : 80;
    const y = this.verticalField * Math.random() + 30;
    this._viewTween = gsap
      .to(this._view, {
        overwrite: true,
        ease: randomize(this._eases),
        onComplete: () => {
          this.toggleDir();
          this.slideTo();
        },
        pixi: {
          x,
          y,
          tint: 0xffffff,
        },
      })
      .duration(this.zipDuration);
  };

  private yoyoEmitter = () => {
    this.slideTo();
  };

  private _onRandomTimeout = () => {
    if (Math.round(this._randomSeed * Math.random()) === this._randomKey) {
      this._emitter(this._view.x, this._view.y + 10);
      this._view.play();
    }
  };

  private handleTimeout = () => {
    this.onRandomTimeout();
    this.randomEmitter();
  };

  private randomEmitter = () => {
    window.clearTimeout(this._randomTimeout);
    this._randomTimeout = window.setTimeout(
      this.handleTimeout,
      this._randomInterval
    );
  };

  private onAdded = () => {
    this.yoyoEmitter();
    this.randomEmitter();
    this._view.off('added', this.onAdded);
    this._view.on('removed', this.onRemoved);
  };

  private onRemoved = () => {
    window.clearTimeout(this._randomTimeout);
    gsap.killTweensOf(this._view);
    this._view.off('removed', this.onRemoved);
  };

  get view() {
    return this._view;
  }
}

export default Pokemitter;
