import React, { useContext, useEffect } from 'react';
import { ModalStateContext } from './index';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import { ModalStyles } from "./ModalStyles";

export const ModalRenderer: React.FC<{}> = () => {
  const modalState = useContext(ModalStateContext);

  useEffect(
    () => {
      const body = document.querySelector('body');
      if (modalState.element) {
        const modal = document.querySelector('#ag-modal');
        if(modal)
        {
          disableBodyScroll(modal);
          body?.classList.add('mouse-locked');
        }
      } else {
        clearAllBodyScrollLocks();
        body?.classList.remove('mouse-locked');
      }

      return clearAllBodyScrollLocks;
    },
    [modalState.element]
  );

  if (modalState.element) {
    return (
      <ModalStyles>
      <div className={'modal'} id={'ag-modal'}>
        {modalState.element}
      </div>
      </ModalStyles>
    );
  }
  return null;
};

export default ModalRenderer;
