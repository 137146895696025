import { Container, Sprite } from 'pixi.js';
import pixi from '../../lib/pixi';
import { testApp } from '../index';

class HealthOverlay extends Container {
  private overlay: Sprite;

  private _health: number = 100;
  get health() {
    return this._health;
  }

  set health(v: number) {
    this._health = v;
    const percentDamage = 100 - v;
    this.alpha = 1 - (100 - percentDamage) / 100;
  }

  constructor() {
    super();
    this.overlay = this.generateBG();
    this.addChild(this.overlay);
    this.pivot.set(this.width / 2, this.height / 2);
    this.alpha = 0;
  }

  private generateBG = (): Sprite => {
    const bg = new pixi.Graphics();
    bg.beginFill(0x480403);
    bg.drawRect(0, 0, window.innerWidth, window.innerHeight);
    bg.alpha = 0.5;
    bg.endFill();
    const texture = testApp.renderer.generateTexture(
      bg,
      pixi.SCALE_MODES.LINEAR,
      1
    );
    return new pixi.Sprite(texture);
  };
}

export default HealthOverlay;
