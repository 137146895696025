import { Container, Sprite } from 'pixi.js';
import pixi from '../../lib/pixi';
import { generateBGSprite } from './Starfield';

class StarCluster extends Container {
  private _bgLayer: Sprite;
  private _stars: Container;

  private _rotateBy: number;
  get rotateBy() {
    return this._rotateBy;
  }
  set rotateBy(v: number) {
    this._rotateBy = v;
  }

  constructor(rotateBy: number = 0.0001) {
    super();
    this._rotateBy = rotateBy;
    this._bgLayer = generateBGSprite(window.innerWidth, window.innerHeight);
    this.addChild(this._bgLayer);
    this._stars = this.createParticles(300);
    this.addChild(this._stars);
    this.cacheAsBitmap = true;
  }

  /**
   * Create the star field background thingy
   * @param numParticles
   */
  private createParticles = (numParticles: number = 100): Container => {
    const group = new pixi.Container();
    group.pivot.x = 0.5;
    group.pivot.y = 0.5;

    for (let i = 0; i < numParticles; i += 1) {
      const p = new pixi.Graphics();
      p.beginFill(0xffffff);
      p.drawRect(
        window.innerWidth * Math.random(),
        window.innerHeight * Math.random(),
        1,
        1
      );
      p.endFill();
      p.alpha = 0.6 * Math.random();
      group.addChild(p);
    }

    return group;
  };
}

export default StarCluster;
