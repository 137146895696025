import React, { Dispatch, useContext, useReducer } from 'react';
import { modalReducer, initialState, IModalState } from './modalReducer';

const dispatch: Dispatch<any> = () => {};
const initialModalState: IModalState = { ...initialState };
export const ModalStateContext = React.createContext(initialModalState);
export const ModalDispatchContext = React.createContext({
  dispatch,
});

export const ModalProvider: React.FC<{}> = (props) => {
  const [state, dispatch] = useReducer(modalReducer, initialModalState);
  return (
    <ModalDispatchContext.Provider value={{ dispatch }}>
      <ModalStateContext.Provider value={state}>
        {props.children}
      </ModalStateContext.Provider>
    </ModalDispatchContext.Provider>
  );
};

export const useModalDispatch = () => useContext(ModalDispatchContext).dispatch;
