import { Container, Sprite } from 'pixi.js';
import gsap from '../../../lib/gsap';
import { generateBGSprite, generateCircleSprite } from '../Starfield';
import { debounce } from '../../utils';
import pixi from '../../../lib/pixi';
// import { GAME_DIMENSIONS } from '../../index';

class TrippyBackground extends Container {
  _bgLayer: Sprite;
  // _sky: Sprite;
  // _floor: Sprite;
  _circles: Sprite[] = [];
  _onTransitionIn: any;
  _onTransitionOut: any;

  constructor(
    onTransitionIn: any = () => console.log('transition in'),
    onTransitionOut: any = () => console.log('transition out'),
    w: number = window.innerWidth,
    h: number = window.innerHeight
  ) {
    super();
    this._onTransitionIn = onTransitionIn;
    this._onTransitionOut = onTransitionOut;
    this._bgLayer = generateBGSprite(w, h, 0x0a1628, 0.8);
    this.pivot.x = 0.5;
    this.pivot.y = 0.5;
    this.addChild(this._bgLayer);
    const matrixFilter = new pixi.filters.ColorMatrixFilter();
    const blurFilter = new pixi.filters.BlurFilter(20 * Math.random() + 10);
    blurFilter.blendMode = pixi.BLEND_MODES.OVERLAY;
    this.filters = [matrixFilter, blurFilter];
    matrixFilter.lsd(true);
    matrixFilter.alpha = 0.75;

    this.mask = generateCircleSprite(window.innerWidth, 0xff3300, 1);
    this.mask.pivot.x = this.mask.width / 2;
    this.mask.pivot.y = this.mask.height / 2;
    this.mask.x = this._bgLayer.width / 2;
    this.mask.y = this._bgLayer.height / 2;
    this.mask.scale.x = 0;
    this.mask.scale.y = 0;
    this.addChild(this.mask);
    this.on('added', this.onAddedToStage);

    for (let i = 0; i < 3; i += 1) {
      const circleSprite = new Sprite(pixi.utils.TextureCache['swirl-1.png']); //generateCircleSprite(800, 0x44c000, 0.1);
      this._circles[this._circles.length] = circleSprite;
      this.addChild(circleSprite);
    }
  }

  private tints = [0xff3300, 0xd700ef, 0xff30d3, 0x5dff51, 0x7d00ae];

  private randomAnimation = (sprite: Sprite) => {
    gsap
      .to(sprite, {
        pixi: {
          scale: Math.random() * 1 + Math.random() * 1,
          alpha: Math.random() * 0.8,
          rotation: Math.random() * 360 + Math.random() * 360,
          onComplete: () => {
            this.randomAnimation(sprite);
          },
        },
      })
      .duration(2);
  };

  private animateCircle = (
    sprite: Sprite,
    delay: number = 0,
    tint: number = 0xffffff
  ) => {
    sprite.pivot.x = sprite.width / 2;
    sprite.pivot.y = sprite.width / 2;
    sprite.tint = tint;
    sprite.scale.set(0, 0);
    sprite.alpha = 0;

    const scaleTo = Math.random() + 1;

    gsap
      .fromTo(
        sprite,
        {
          pixi: {
            scale: 0,
            alpha: 0,
            rotation: 0,
            x: this._bgLayer.width / 2,
            y: this._bgLayer.height / 2,
            ease: 'elastic.inOut',
          },
        },
        {
          pixi: {
            scaleX: scaleTo,
            scaleY: scaleTo,
            alpha: 0.5 * Math.random(),
            rotation: 1200 * Math.random() + 360,
            ease: 'elastic.inOut',
          },
        }
      )
      .delay(delay)
      .duration(6);
    // .repeat(-1);
  };

  private handleResize = () => {
    this._bgLayer.width = window.innerWidth;
    this._bgLayer.height = window.innerHeight;
  };

  private debouncedResize = debounce(this.handleResize);

  private onRemovedFromStage = () => {
    window.removeEventListener('resize', this.debouncedResize);
    this.off('removed', this.onRemovedFromStage);
  };

  public transitionOut = () => {
    gsap
      .to(this.mask, {
        pixi: { scaleY: 0, scaleX: 0 },
        onComplete: this._onTransitionOut,
      })
      .duration(1.2);
  };

  private onAddedToStage = () => {
    gsap
      .to(this.mask, {
        pixi: { scaleY: 1, scaleX: 1 },
        onComplete: this._onTransitionIn,
      })
      .duration(3);

    this._circles.forEach((circle: Sprite, i: number) => {
      // this.randomAnimation(circle);
      this.animateCircle(circle, 0.6 * i, this.tints[i]);
    });

    window.addEventListener('resize', this.debouncedResize);
    this.off('added', this.onAddedToStage);
    this.on('removed', this.onRemovedFromStage);
  };
}

export default TrippyBackground;
