import { Sprite, Loader, LoaderResource } from 'pixi.js';
import pixi from '../lib/pixi';
import { testApp } from './index';

interface IMenuBtns {
  start?: Sprite;
  how?: Sprite;
  leaders?: Sprite;
}

const TOP_OFFSET = -80;

const onRollover = (e: MouseEvent) => {
  if (e.currentTarget) (e.currentTarget as any).tint = 0xff3300;
};

const onRollout = (e: MouseEvent) => {
  if (e.currentTarget) (e.currentTarget as any).tint = 0xffffff;
};

class StartScreen extends pixi.Container {
  private bgLayer: Sprite;
  private titleImage: Sprite | undefined = undefined;
  private _gameWidth: number;
  private _gameHeight: number;
  private loader: Loader;
  private menu: IMenuBtns = {};
  private onGameStart: any = undefined;
  private _eventBus: any;
  private _isMobile: boolean = pixi.utils.isMobile.any;
  private _frameID: number = -1;

  constructor(
    gameWidth: number,
    gameHeight: number,
    onGameStart: any,
    eventBus: any
  ) {
    super();
    this._eventBus = eventBus;
    this._gameWidth = gameWidth;
    this._gameHeight = gameHeight;
    this.bgLayer = this.generateBG();
    this.addChild(this.bgLayer);
    this.pivot.x = this.bgLayer.width / 2;
    this.pivot.y = this.bgLayer.height / 2;
    this.loader = new Loader();
    this.interactive = true;
    this.interactiveChildren = true;
    this.onGameStart = onGameStart;

    this.init();

    this.on('added', this.subscribeToTicker);

    this.on('removed', () => {
      // clean up listeners
      window.cancelAnimationFrame(this._frameID);
      this.off('added', this.subscribeToTicker);
      this.removeMenuEventListeners();
    });
  }

  private addMenuEventListeners = () => {
    const { how: howToPlay, start: startGame } = this.menu;
    if (howToPlay && startGame) {
      howToPlay.interactive = startGame.interactive = true;
      howToPlay.buttonMode = startGame.buttonMode = true;

      if (this._isMobile) {
        howToPlay.on('touchstart', this.onHowToTouch);
        howToPlay.on('touchend', this.onHowToTouchEnd);
        startGame.on('touchstart', this.onStartGameTouch);
        startGame.on('touchend', this.onStartGameTouchEnd);
      } else {
        howToPlay.on('mouseover', onRollover);
        howToPlay.on('mouseout', onRollout);
        howToPlay.on('click', this.onHowToClick);
        startGame.on('click', this.onStartGame);
        startGame.on('mouseover', onRollover);
        startGame.on('mouseout', onRollout);
      }
    }
  };

  private onHowToTouch = (e: TouchEvent) => {
    if (e.currentTarget) (e.currentTarget as any).tint = 0xff3300;
  };

  private onStartGameTouch = (e: TouchEvent) => {
    if (e.currentTarget) (e.currentTarget as any).tint = 0xff3300;
  };

  private onHowToTouchEnd = (e: TouchEvent) => {
    if (e.currentTarget) (e.currentTarget as any).tint = 0xffffff;
    this.onHowToClick();
  };

  private onStartGameTouchEnd = (e: TouchEvent) => {
    if (e.currentTarget) (e.currentTarget as any).tint = 0xffffff;
    this.onStartGame();
  };

  private onHowToClick = () => {
    this._eventBus('showInfo', {});
  };

  private onStartGame = () => {
    if (this.onGameStart) {
      this.onGameStart();
    }
  };

  private removeMenuEventListeners = () => {
    const { how: howToPlay, start: startGame } = this.menu;
    if (howToPlay && startGame) {
      if (this._isMobile) {
        howToPlay.off('touchstart', this.onHowToTouch);
        howToPlay.off('touchend', this.onHowToTouchEnd);
        startGame.off('touchstart', this.onStartGameTouch);
        startGame.off('touchend', this.onStartGameTouchEnd);
      } else {
        howToPlay.off('mouseover', onRollover);
        howToPlay.off('mouseout', onRollout);
        howToPlay.off('click', this.onHowToClick);
        startGame.off('click', this.onStartGame);
        startGame.off('mouseover', onRollover);
        startGame.off('mouseout', onRollout);
      }
    }
  };

  private createMenu = (resources: Partial<Record<string, LoaderResource>>) => {
    // @ts-ignore
    const startGame: Sprite = new Sprite(
      // @ts-ignore
      resources['images/btn-start.png'].texture
    );

    startGame.pivot.set(startGame.width / 2, startGame.height / 2);
    startGame.x = this.bgLayer.width / 2;
    if (this.titleImage) {
      startGame.y = this.titleImage.y + this.titleImage.height / 2 + 40;
    }

    const howToPlay: Sprite = new Sprite(
      // @ts-ignore
      resources['images/btn-how.png'].texture
    );

    this.menu.start = startGame;
    this.menu.how = howToPlay;

    howToPlay.pivot.set(howToPlay.width / 2, howToPlay.height / 2);
    howToPlay.x = this.bgLayer.width / 2;
    howToPlay.y = startGame.y + 60;

    this.addMenuEventListeners();

    this.addChildAt(startGame, 0);
    this.addChild(howToPlay);
  };

  private renderize = (time: number) => {
    testApp.ticker.update(time);
    testApp.renderer.render(testApp.stage);
    this._frameID = requestAnimationFrame(this.renderize);
  };

  private subscribeToTicker = () => {
    this.renderize(performance.now());
  };

  private init = (): void => {
    this.loader.add([
      'images/logo.png',
      'images/btn-start.png',
      'images/btn-how.png',
      'images/btn-leaderboard.png',
    ]);
    this.loader.load((loader, resources) => {
      // @ts-ignore
      this.titleImage = new pixi.Sprite(resources['images/logo.png'].texture);
      this.titleImage.pivot.x = this.titleImage.width / 2;
      this.titleImage.pivot.y = this.titleImage.height / 2;
      this.titleImage.x = this._gameWidth / 2;
      this.titleImage.y = this._gameHeight / 2 + TOP_OFFSET;
      this.addChild(this.titleImage);
      this.createMenu(resources);
    });
  };

  private generateBG = (): Sprite => {
    const bg = new pixi.Graphics();
    bg.beginFill(0x333333);
    bg.lineStyle(1, 0x999999);
    bg.drawRect(0, 0, this._gameWidth, this._gameHeight);
    bg.alpha = 0;
    bg.endFill();
    const texture = testApp.renderer.generateTexture(
      bg,
      pixi.SCALE_MODES.LINEAR,
      1
    );
    return new pixi.Sprite(texture);
  };
}

export default StartScreen;
