import styled from 'styled-components';

export const ModalStyles = styled.div`
  .modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    pointer-events: initial;
    z-index: 10000;
    overflow-y: auto;
    padding-bottom: 120px;
  }
`;
