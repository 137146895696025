import { Container, Texture, Sprite } from 'pixi.js';

class ShotMeter extends Container {
  private _clipSize: number;
  private _shotsLeft: number;
  private _shotTexture: Texture;
  private _shots: Sprite[] = [];

  get clipSize() {
    return this._clipSize;
  }

  set clipSize(v: number) {
    this._clipSize = v;
    this.renderShots();
  }

  get shotsLeft() {
    return this._shotsLeft;
  }

  set shotsLeft(v: number) {
    this._shotsLeft = v;
    this.updateShooter();
  }

  constructor(
    shotTexture: Texture,
    clipSize: number = 6,
    shotsLeft: number = 6
  ) {
    super();
    this._clipSize = clipSize;
    this._shotsLeft = shotsLeft;
    this._shotTexture = shotTexture;
    this.renderShots();
  }

  updateShooter = () => {
    this._shots.forEach((shot: Sprite, i: number) => {
      if (i < this.shotsLeft) {
        shot.alpha = 1;
      } else {
        shot.alpha = 0.5;
      }
    });
  };

  renderShots = () => {
    this._shots.forEach((shot: any) => {
      this.removeChild(shot);
    });

    this._shots = [];

    for (let i = 0; i < this._clipSize; i += 1) {
      const bullet = new Sprite(this._shotTexture);
      bullet.width = bullet.height = 8;
      bullet.x = 8 * i;
      this._shots.push(bullet);
      this.addChild(bullet);
    }
  };
}

export default ShotMeter;
