import { EventEmitter } from 'events';
import { AnimatedSprite } from 'pixi.js';

class RIPPopSmoke extends EventEmitter {
  private _view: any;

  private _timer: number | undefined = undefined;

  get view() {
    return this._view;
  }

  constructor(
    view: AnimatedSprite,
    width: number,
    height: number,
    x: number = 0,
    y: number = 0
  ) {
    super();
    this._view = view;
    this._view.width = width;
    this._view.height = height;
    this._view.pivot.x = width / 2;
    this._view.pivot.y = height / 2;
    this._view.x = x;
    this._view.y = y;
    this._view.animationSpeed = 0.2;
    this._view.on('added', this.onAdded);
  }

  private onAdded = () => {
    this._view.gotoAndPlay(1);
    this._timer = window.setTimeout(() => {
      this.emit('REMOVE_POOF');
    }, 400);
  };
}

export default RIPPopSmoke;
