import {AnimatedSprite, Container, Loader, Sprite, Texture} from 'pixi.js';
import pixi from '../lib/pixi';
import gsap from '../lib/gsap';
import { EventBus } from '../types';
import Pokemitter from './elements/Pokemitter';
import CornNugget from './elements/CornNugget';
import { testApp } from './index';
import PoopShooter from './elements/PoopShooter';
import Enemy from './elements/Enemy';
import { checkHits } from './utils/hit';
import RIPPopSmoke from "./elements/RIPPopSmoke";
import SpecialDrop, { SpecialType, SpecialTextures } from "./elements/SpecialDrop";
import randomize from "./utils";
import ShotMeter from "./elements/ShotMeter";
import HealthOverlay from "./elements/HealthOverlay";
import Scoreboard from "./elements/Scoreboard";
import Scorekeeper from "./elements/Scorekeeper";
import GameOver from "./elements/GameOver";
import ThemeManager from "./elements/ThemeManager";
import BackgroundManager from "./elements/BackgroundManager";
import TouchControls from "./elements/TouchControls";

const WARP_BUFFER = 20;
const ufoFrames = [
  'ball_00.png',
  'ball_01.png',
  'ball_02.png',
  'ball_03.png',
  'ball_04.png',
  'ball_05.png',
  'ball_05.png',
  'ball_04.png',
  'ball_03.png',
  'ball_02.png',
  'ball_01.png',
  'ball_00.png',
];
const poofFrames = ['pop-0.png', 'pop-1.png', 'pop-2.png'];
const splatFrames = ['splat_01.png', 'splat_02.png', 'splat_03.png', 'splat_04.png'];
const pikaFrames = [
  'pika_1.png',
  'pika_2.png',
  'pika_3.png',
  'pika_4.png',
  'pika_5.png',
  'pika_6.png',
  'pika_7.png',
  'pika_8.png',
  'pika_9.png',
  'pika_10.png',
];

const MAX_SHOTS = 7;
const START_LIVES = 2;

/**
 * Main game class - handles game logic
 */
class SpaceButtBlaster extends Container {
  private _eventBus: EventBus = (event: string, data: any = {}) =>
    console.log(event, data);

  private shotMeter: ShotMeter | undefined;
  private isButtBlasting: boolean = false;
  private isFirebutt: boolean = false;
  private isShrooming: boolean = false;
  private gameIsActive: boolean = true;
  private _assetLoader: any;
  private _enemyEmitter: Pokemitter | undefined = undefined;
  private scorekeeper: any = Scorekeeper;
  private _health: number = 100;
  private _bgManager: typeof BackgroundManager;

  set health(v: number){
    this._health = v;
    this.healthOverlay.health = v;
  }
  get health(){
    return this._health;
  }
  /**
   * PUBLIC PROPS
   */
  private _texturesLoaded: boolean = false;
  set texturesLoaded(v: boolean) {
    this._texturesLoaded = v;
  }
  get texturesLoaded() {
    return this._texturesLoaded;
  }

  private _gameWidth: number;
  get gameWidth(){
    return this._gameWidth
  }

  private _gameHeight: number;
  get gameHeight(){
    return this._gameHeight;
  }

  private _enemies: any[] = [];
  get enemies(){
    return this._enemies;
  }
  set enemies(v: any[]){
    this._enemies = v;
  }
  private rockets: CornNugget[] = [];
  private specials: SpecialDrop[] = [];
  private pikaTextures: any = undefined;
  private _specialTextures: SpecialTextures = {};

  private enemiesSpawned: number = 0;
  private enemiesBlasted: number = 0;
  private specialsSpawned: SpecialType[] = [];
  private specialsCollected: SpecialType[] = [];
  private lives: number = START_LIVES;

  private _pooper: PoopShooter | undefined = undefined;
  private bgLayer: Sprite;
  private healthOverlay: HealthOverlay;

  private mobileControls: TouchControls | null = null;


  constructor(eventBus: EventBus, bgManager: typeof BackgroundManager, gameWidth = 420, gameHeight = 550) {
    super();
    this._bgManager = bgManager;
    this._eventBus = eventBus;
    this._gameWidth = gameWidth;
    this._gameHeight = gameHeight;
    this.width = gameWidth;
    this.height = gameHeight;
    this.bgLayer = this.generateBG();
    this.healthOverlay = new HealthOverlay();

    this.init();
  }

  private init() {
    this.loadTextures();
    this.addChild(this.bgLayer);
    this.healthOverlay.x = this.bgLayer.x + this.bgLayer.width/2;
    this.healthOverlay.y = this.bgLayer.y + this.bgLayer.height/2;
    this.addChildAt(this.healthOverlay, 0);

  }

  private generateBG = (): Sprite => {
    const bg = new pixi.Graphics();
    bg.beginFill(0x333333);
    bg.lineStyle(1,0x999999);
    bg.drawRect(0,0, this._gameWidth, this._gameHeight);
    bg.alpha = 0.08;
    bg.endFill();
    const texture = testApp.renderer.generateTexture(bg,pixi.SCALE_MODES.LINEAR,1);
    return new pixi.Sprite(texture);
  }

  private addEnemy = (
    x: number = 50 + (this._gameWidth - 100) * Math.random(),
    y: number = Math.random() * 50
  ) => {
    if (this.pikaTextures && !this.isButtBlasting) {
      const wh = 15 + Math.round(Math.random()+15);
      const enemy = new Enemy(
        // @ts-ignore
        this.pikaTextures,
        wh,
        wh,
        x,
        y
      );
      this.enemies[this.enemies.length] = enemy;
      this.addChildAt(enemy.view, 0);
      this.enemiesSpawned += 1;
      this.scorekeeper.total = this.enemiesSpawned;

      // this.scoreboard?.update(this.enemiesBlasted, this.enemiesSpawned);
      // this._eventBus('enemyBlasted', { count: this.enemiesBlasted, enemiesTotal: this.enemiesSpawned });
    }
  };

  private poofTextures: Texture[] = [];
  private _poofSprite1: AnimatedSprite | undefined = undefined;
  private _poofSprite2: AnimatedSprite | undefined = undefined;

  private scoreboard: Scoreboard | undefined = undefined;
  private _tripoutTimeout: number | undefined;

  private drawElements = (): void => {

    this.scoreboard = new Scoreboard();
    this.scoreboard.x = Math.round(this.gameWidth - this.scoreboard.width - 20);
    this.scoreboard.y = 15;
    this.addChild(this.scoreboard);

    this.poofTextures = poofFrames.map(
      frame => this._assetLoader.resources.sprites.textures[frame]
    );
    this._poofSprite1 = new pixi.AnimatedSprite(this.poofTextures);
    const splatTextures = splatFrames.map(
      frame => this._assetLoader.resources.sprites.textures[frame]
    );
    this._poofSprite2 = new pixi.AnimatedSprite(splatTextures);

    this._specialTextures = {
      taco: this._assetLoader.resources.sprites.textures['taco.png'],
      hotdog: this._assetLoader.resources.sprites.textures['hot-dog.png'],
      broccoli: this._assetLoader.resources.sprites.textures['broccoli.png'],
      wing: this._assetLoader.resources.sprites.textures['wing.png'],
      shroom: this._assetLoader.resources.sprites.textures['shroom.png'],
    };


    const ufoTextures = ufoFrames.map(
      // @ts-ignore
      frame => this._assetLoader.resources.sprites.textures[frame]
    );
    this._enemyEmitter = new Pokemitter(
      ufoTextures,
      50,
      50,
      this._gameWidth/2,
      -500,
      this.addEnemy,
      this._gameWidth,
      this._gameHeight
    );
    this.addChildAt(this._enemyEmitter.view, 1);
    this.pikaTextures = pikaFrames.map(
      // @ts-ignore
      frame => this._assetLoader.resources.sprites.textures[frame]
    );

    this._pooper = new PoopShooter(
      // @ts-ignore
      this._assetLoader.resources.sprites.textures['pooper.png'],
      40,
      40,
      this._gameWidth / 2 - 20,
      this._gameHeight - 60,
      this.firePoopRocket
    );

    this.shotMeter = new ShotMeter(this._assetLoader.resources.sprites.textures['corn.png']);
    this.shotMeter.x = 20;
    this.shotMeter.y = 20;
    this.shotMeter.clipSize = MAX_SHOTS;
    this.addChild(this.shotMeter);
    this.addChild(this._pooper.view);

    if(pixi.utils.isMobile.any)
    {
      this.mobileControls = new TouchControls(this.firePoopRocket, this._pooper.moveLeft, this._pooper.moveRight, this._pooper.tweenToZero);

      this.mobileControls.y = window.innerHeight - 120;
      this.mobileControls.pivot.x = this.mobileControls.width/2;
      this.mobileControls.x = this.mobileControls.width/2;
      this.addChild(this.mobileControls);
    }


    // After initial elements are drawn subscribe to the ticker to start animation
    this.subscribeToTicker();
    this.scorekeeper.logGameStart();

    // setTimeout(() => {
    //   const themeManager = ThemeManager;
    //   themeManager.theme = 'tripout';
    //   setTimeout(() => {
    //     // @ts-ignore
    //     this._bgManager.resetBackground();
    //   }, 6000);
    // }, 1000);

  };


  private updateGameElements = () => {
    this.movePooper();
    this.moveRockets();
  };

  private onRemovePoofElement = (el: AnimatedSprite)=>{
    this.removeChild(el);
  }

  private createPoof = (sprite: AnimatedSprite, x: number = this._gameWidth/2, y: number = this._gameHeight/2, tint: any = 0xffffff) => {
    const popper = new RIPPopSmoke(Object.assign(sprite) as AnimatedSprite, 50, 50, x, y);
    const onRemovePoof = ()=>{
      this.onRemovePoofElement(popper.view);
      popper.off('REMOVE_POOF',onRemovePoof);
    }
    popper.on('REMOVE_POOF',onRemovePoof);
    popper.view.rotation = Math.random()*40 - Math.random()*80;
    popper.view.tint = tint;
    popper.view.tint = tint;
    const scaler = 0.6*Math.random() + 0.7;
    popper.view.scale.x = scaler;
    popper.view.scale.y = scaler;
    this.addChildAt(popper.view, this.children.length);
  };

  private firePoopRocket = () => {
    if (this._pooper && this.rockets.length < MAX_SHOTS) {
      const nug = new CornNugget(
        // @ts-ignore
        this._assetLoader.resources.sprites.textures['corn.png'],
        4,
        40,
        this._pooper.view.x + 20,
        this._pooper.view.y - 15
      );

      if(this.isFirebutt)
      {
        nug.view.tint = 0xff3300;
        nug.view.width = 12;
        nug.view.x = nug.view.x - 6;
      }

      this.rockets[this.rockets.length] = nug;
      this.updateShotsMeter();
      this.addChildAt(nug.view, 0);
      this.scorekeeper.shotsFired += 1;

    }
  };


  private makeSpecial = (type: SpecialType = 'taco'):SpecialDrop =>{
    return new SpecialDrop(type,this._specialTextures);
  }

  private checkRocket = (rocket: CornNugget, i: number) => {
    rocket.view.y = rocket.view.y - rocket.movementY;
    let trackAsHit: boolean = false;
    if(this.isFirebutt)
    {
      rocket.view.width = rocket.view.width += 1;
    }
    if (rocket.view.height > 5) rocket.view.height = rocket.view.height - 0.6;
    let hitEnemy = false;
    let hitEmitter = false;
    if(checkHits(rocket.view, this._enemyEmitter?.view))
    {
      trackAsHit = true;
      hitEmitter = true;
      this._enemyEmitter?.onEmitterShot();
      if(this._poofSprite2)
      {
        this.createPoof(Object.create(this._poofSprite2), rocket.view.x, rocket.view.y-25);
      }

      if(this.specials.length < 1 && !this.isShrooming)
      {
        // ['shroom','taco','taco','hotdog','hotdog','broccoli','wing']
        const specialType: SpecialType = randomize(['shroom','taco','taco','hotdog','hotdog','broccoli','wing']) as SpecialType;
        const taco = this.makeSpecial(specialType);
        this.specialsSpawned[this.specialsSpawned.length] = specialType;
        this.scorekeeper.trackSpecialsDropped();
        taco.x = rocket.view.x;
        taco.y = rocket.view.y - Math.random()*30;
        this.specials[this.specials.length] = taco;
        this.addChildAt(taco, 0);
      }
    }

    this.enemies.forEach((enemy: Enemy) => {
      if (checkHits(rocket.view, enemy.view)) {
        trackAsHit = true;
        this.killEnemy(enemy);
        hitEnemy = !this.isFirebutt;
        if(this.health < 100)
        {
          this.health += 1;
        }
      }
    });

    if(trackAsHit)
    {
      this.scorekeeper.shotsHit += 1;
    }
    if (rocket.movementY <= 0.2 || hitEnemy || hitEmitter) {
      this.removeChild(rocket.view);
      this.rockets.splice(i, 1);
      this.updateShotsMeter();
    }
  };

  private updateShotsMeter = ()=>{
    if(this.shotMeter)
    {
      this.shotMeter.shotsLeft = MAX_SHOTS - this.rockets.length;
    }
  }

  // Tweak the emitter on each enemy killed til a fast paced game takes you out
  private adjustEnemyEmitter = ()=>{
    if (this._enemyEmitter) {
      if (this._enemyEmitter.randomInterval > 100) {
        this._enemyEmitter.randomInterval =
          this._enemyEmitter.randomInterval - 1;
      }
      if (this._enemyEmitter.zipDuration > 1.3) {
        this._enemyEmitter.zipDuration =
          this._enemyEmitter.zipDuration - 0.005;
      }

      if(this._enemyEmitter.verticalField < 300 && !this.isShrooming)
      {
        this._enemyEmitter.verticalField += 0.05;
      }
    }
  }

  // Move some rockets, move some enemies
  private moveRockets = () => {
    this.rockets.forEach(this.checkRocket);
    this.moveEnemies();
  };

  private moveEnemies = () => {
    if(!this.isButtBlasting)
    {
      this.enemies.forEach((enemy: Enemy, i: number) => {
        enemy.view.y = this.isShrooming ? enemy.view.y + enemy.speed : enemy.view.y - enemy.speed;
        enemy.speed = enemy.speed - enemy.velo;
        if(enemy.view.y > window.innerHeight)
        {
          enemy.view.y = -200 - Math.random()*200;
          enemy.view.x = Math.random()*(this.gameWidth - 80) + 40;
          this.enemiesSpawned += 1;
          this.scorekeeper.total = this.enemiesSpawned;
          this.scorekeeper.trackEnemiesFlipped();
        }

        // @ts-ignore
        if(this._pooper && checkHits(this._pooper.view,enemy.view))
        {
          this.removeChild(enemy.view);
          this.enemies.splice(i, 1);
          this.health = this.health - 50;
          if(this._poofSprite2)
          {
            this.createPoof(Object.create(this._poofSprite2), this._pooper.view.x + Math.random()*30, this._pooper.view.y + Math.random()+20, 0xff3300);
          }
          if(this.health < 1)
          {
            this.checkLives();
          }
        }
      });
    }else{
      // console.log('EXECUTE THE TACO BLAST ANIMATIONS!!!');
    }
    this.specials.forEach(this.moveActiveDrop);
  };

  private checkLives = ()=>{
    if(this.lives > 0 && this._pooper)
    {
      this.respawn();
    }else{
      this.gameOver();
    }
  }

  private respawn = ()=>{
    if(this._pooper?.view)
    {
      this.lives -= 1;
      this.health = 100;
      this.removeChild(this._pooper.view);
      this.scorchedEarth();
      this._pooper.view.x = this.bgLayer.width/2;
      window.setTimeout(()=>{
        this.addChild(this._pooper?.view)
      }, 200)
    }
  }

  private gameOverScreen: any;

  private gameOver = ()=>{
    this.scorchedEarth();
    this.scorekeeper.logGameEnd();


    gsap.to(this,{health: 100}).duration(1.8);
    this.removeChild(this._enemyEmitter?.view);
    this.removeChild(this._pooper?.view);

    if(this.mobileControls !== null)
    {
      this.removeChild(this.mobileControls);
    }

    if(this.scoreboard)this.removeChild(this.scoreboard);
    if(this.shotMeter)this.removeChild(this.shotMeter);

    this.gameOverScreen = new GameOver(this.restartGame, this._gameWidth, this._gameHeight);
    this.addChild(this.gameOverScreen);

    this._eventBus('gameComplete',{score: this.scorekeeper.totalPoints});
  }

  private restartGame = ()=>{
    this.gameIsActive = true;
    this.scorekeeper.reset();
    this.health = 100;
    this.lives = START_LIVES;
    this.isFirebutt = false;
    this.isShrooming = false;
    this.isButtBlasting = false;
    this.enemiesBlasted = 0;
    this.enemiesSpawned = 0;
    this.specialsCollected.length = 0;
    this.specialsSpawned.length = 0;
    this._enemyEmitter?.resetEmitter();
    this.resetDisplayObjectArr(this.rockets);
    this.resetDisplayObjectArr(this.enemies);
    this.resetDisplayObjectArr(this.specials);
    // @ts-ignore
    this._pooper.view.x = this.bgLayer.width/2;
    this.removeChild(this.gameOverScreen);
    this.gameOverScreen = undefined;


    setTimeout(()=>{
      if(this.scoreboard)this.addChild(this.scoreboard);
      if(this.shotMeter)this.addChild(this.shotMeter);
      this.addChild(this._enemyEmitter?.view);
      this.addChild(this._pooper?.view);
      if(this.mobileControls !== null)
      {
        this.addChild(this.mobileControls);
      }
      this.scorekeeper.logGameStart();
    }, 400);
  }

  private resetDisplayObjectArr = (arr: any)=>{
    arr.forEach((obj:any)=>{
      if(obj.view)
      {
        this.removeChild(obj.view);
      }
      if(obj.isSprite)
      {
        this.removeChild(obj);
      }
    });
    arr.length = 0;
  }

  private killEnemy = (enemy: Enemy, autoRemove: boolean = true): void => {
    this.removeChild(enemy.view);
    this.createPoof(new pixi.AnimatedSprite(this.poofTextures), enemy.view.x+15, enemy.view.y+25);
    if(autoRemove)
    {
      this.enemies.splice(this.enemies.indexOf(enemy), 1);
    }
    this.adjustEnemyEmitter();
    this.enemiesBlasted += 1;
    this.scorekeeper.kills = this.enemiesBlasted;
  }

  private eradicate = (): void=>{
    const luckyOnes: Enemy[] = [];
    this.enemies.forEach((enemy: Enemy, i: number)=>{
      if(i % 2 === 1){
        this.killEnemy(enemy, false);
      }else{
        luckyOnes[luckyOnes.length] = enemy;
      }
    });
    this.enemies = luckyOnes;
  }

  private scorchedEarth = ():void => {
    this.enemies.forEach((enemy: Enemy, i: number)=>{
      this.killEnemy(enemy, false);
    });
    this.enemies = [];
  }



  private moveActiveDrop = (drop: SpecialDrop, i: number): void =>{
    drop.y += drop.moveSpeed;
    let removeDrop = drop.y >= this._gameHeight;
    if(this._pooper && checkHits(this._pooper.view,drop))
    {
      removeDrop = true;
      this.specialsCollected[this.specialsCollected.length] = drop.type;
      this.scorekeeper.trackSpecialsCaught();
      switch(drop.type) {
        case 'taco':
          this.activateButtBlastMode(drop.duration);
          break;
        case 'hotdog':
          this.eradicate();
          this.activateButtBlastMode(drop.duration);
          break;

        case 'broccoli':
          this.scorchedEarth();
          break;

        case 'shroom':
          const themeManager = ThemeManager;
          if(themeManager.theme !== 'tripout'){
            themeManager.theme = 'tripout';
          }
          this.isShrooming = true;
          if(this._enemyEmitter)
          {
            this._enemyEmitter.verticalField = 300;
          }
          if(this._tripoutTimeout) window.clearTimeout(this._tripoutTimeout);
          this._tripoutTimeout = window.setTimeout(()=>{
            this.isShrooming = false;
            // @ts-ignore
            this._bgManager.resetBackground();
            if(this._enemyEmitter)
            {
              this._enemyEmitter.verticalField = 60
            }
          }, drop.duration);
          break;

        case 'wing':
          this.isFirebutt = true;
          this._pooper.view.tint = 0xff3300;
          window.setTimeout(()=>{
            this.isFirebutt = false
            // @ts-ignore
            this._pooper.view.tint = 0xffffff;
          }, drop.duration);
          break;
      }
    }

    if(removeDrop)
    {
      this.specials.splice(i,1);
      this.specials.length = 0;
      this.removeChild(drop);
    }
  };

  /**
   * Render game loop with gsap
   * @param time
   */
  private renderize = (time: number) => {
    this.updateGameElements();
    testApp.ticker.update(time);
    testApp.renderer.render(testApp.stage);
    requestAnimationFrame(this.renderize);
  };

  private activateButtBlastMode = (duration: number)=>{
    this.isButtBlasting = true;
    window.setTimeout(()=>{
      this.isButtBlasting = false
    }, duration);
  }

  private subscribeToTicker = () => {
    this.renderize(performance.now());
  };

  private movePooper = () => {
    const {_pooper, gameWidth} = this;
    if (_pooper) {
      _pooper.view.x = _pooper.view.x + _pooper.movementX;
      if(_pooper.view.x < 0 - WARP_BUFFER)
      {
        _pooper.view.x = gameWidth - _pooper.view.width/2;
      }
      if(_pooper.view.x > gameWidth + WARP_BUFFER/2)
      {
        _pooper.view.x = 0 - _pooper.view.width/2;
      }
    }
  };

  private loadTextures = (): void => {
    const assetLoader = new Loader();
    assetLoader.add('sprites', 'images/spritesheet.json');
    assetLoader.load((assetLoader, resources) => {
      this._eventBus('TEXTURES_LOADED');
      this._assetLoader = assetLoader;
      this.drawElements();
    });
  };
}

export default SpaceButtBlaster;
