import pixi from '../../lib/pixi';
import { EventEmitter } from 'events';

class Enemy extends EventEmitter {
  private _view: any;

  constructor(
    texture: any,
    width: number,
    height: number,
    x: number = 0,
    y: number = 0
  ) {
    super();
    this._view = new pixi.AnimatedSprite(texture);
    this._view.animationSpeed = 0.4 * Math.random() + 0.15;
    this._view.loop = false;

    this._view.on('added', () => {
      window.setTimeout(() => {
        this._view.play();
      }, Math.random() * 1500 + 2000);
    });
    this._view.onComplete = () => {
      this._view.gotoAndPlay(4);
    };

    this._view.width = width;
    this._view.height = height;
    const randomScale = .7 + Math.random()*.3;
    this._view.scale.x = randomScale;
    this._view.scale.y = randomScale;
    this._view.alpha = randomScale;
    this._view.x = x;
    this._view.y = y;
  }

  private _speed: number = -0.4;
  private _velo: number = 0.002;

  get velo() {
    return this._velo;
  }

  set velo(v: number) {
    this._velo = v;
  }

  get speed() {
    return this._speed;
  }

  set speed(val: number) {
    this._speed = val;
  }

  get view() {
    return this._view;
  }
}

export default Enemy;
