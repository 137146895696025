const randomize = (arr: any[]) => arr[Math.floor(arr.length * Math.random())];

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// @ts-ignore
export function debounce(func, wait = 30, immediate = false) {
  // @ts-ignore
  var timeout;
  return function() {
    // @ts-ignore
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    // @ts-ignore
    var callNow = immediate && !timeout;
    // @ts-ignore
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export default randomize;
