import pixi from '../../lib/pixi';
import gsap from '../../lib/gsap';
import { EventEmitter } from 'events';

class PoopShooter extends EventEmitter {
  private _view: any;
  private _onFire: () => void;

  constructor(
    texture: any,
    width: number,
    height: number,
    x: number = 0,
    y: number = 0,
    onFire: () => void
  ) {
    super();
    this._view = new pixi.Sprite(texture);
    this._view.width = width;
    this._view.height = height;
    this._view.pivot.x = 0.5;
    this._view.pivot.y = 0.5;
    this._view.x = x;
    this._view.y = y;
    this._view.on('added', this.initListeners);
    this._view.on('removed', this.removeListeners);
    this._onFire = onFire;
  }

  private _mouseInterval: number | undefined;
  private _movementX: number = 0;

  set movementX(v: number) {
    this._movementX = v;
  }
  get movementX() {
    return this._movementX;
  }

  MAX_LEFT_MOVE = -3;
  MAX_RIGHT_MOVE = 3;
  MOVEMENT_DURATION = 0.8;

  get view() {
    return this._view;
  }

  private leftKeys = ['s', 'k'];
  private rightKeys = ['d', 'l'];
  private fireKeys = ['w', 'a', ' ', 'j', 'i'];

  handleKeyEvent = (e: KeyboardEvent) => {
    if (this.leftKeys.includes(e.key) || e.keyCode === 37) {
      e.preventDefault();
      this.moveLeft();
    }
    if (this.rightKeys.includes(e.key) || e.keyCode === 39) {
      e.preventDefault();
      this.moveRight();
    }
    if (this.fireKeys.includes(e.key)) {
      e.preventDefault();
      this._onFire();
    }
  };

  handleClick = (e: MouseEvent) => {
    e.preventDefault();
    this._onFire();
  };

  public moveLeft = () => {
    gsap
      .to(this, { movementX: this.MAX_LEFT_MOVE, overwrite: true })
      .duration(this.MOVEMENT_DURATION);
  };

  public moveRight = () => {
    gsap
      .to(this, { movementX: this.MAX_RIGHT_MOVE, overwrite: true })
      .duration(this.MOVEMENT_DURATION);
  };

  public tweenToZero = () => {
    clearInterval(this._mouseInterval);
    // console.log('tween movement factor back down to zero');
    gsap.to(this, { movementX: 0, overwrite: true }).duration(0.5);
  };

  initListeners = () => {
    // console.log('init poop listeners');
    window.addEventListener('keydown', this.handleKeyEvent);
    window.addEventListener('keyup', this.tweenToZero);
    window.addEventListener('mouseup', this.handleClick);
  };

  removeListeners = () => {
    // console.log('remove poop listeners');
    window.removeEventListener('keydown', this.handleKeyEvent);
    window.removeEventListener('keyup', this.tweenToZero);
    window.removeEventListener('mouseup', this.handleClick);
    this.tweenToZero();
  };
}

export default PoopShooter;
