import { EventEmitter } from 'events';
type Theme = 'default' | 'tripout' | 'rainbows' | 'grafshit';
// const THEMES: Theme[] = ['default', 'tripout', 'rainbows', 'grafshit'];

class ThemeManager extends EventEmitter {
  private _theme: Theme = 'default';
  constructor(baseTheme: Theme = 'default') {
    super();
    this._theme = baseTheme;
  }
  set theme(v: Theme) {
    this._theme = v;
    this.emit('UPDATE', { theme: v });
  }
  get theme() {
    return this._theme;
  }
}

export default new ThemeManager();
