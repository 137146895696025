import { Container, Sprite } from 'pixi.js';
import pixi from '../../lib/pixi';
import { generateBGSprite } from './Starfield';
import { getGameDimension } from '../index';

class TouchControls extends Container {
  private _left: Sprite;
  private _right: Sprite;
  private _fire: Sprite;
  private _bg: Sprite;

  private blastEm: () => void;
  private moveLeft: () => void;
  private moveRight: () => void;
  private stopMoving: () => void;

  constructor(blastEm: any, moveLeft: any, moveRight: any, stopMoving: any) {
    super();
    this.blastEm = blastEm;
    this.moveLeft = moveLeft;
    this.moveRight = moveRight;
    this.stopMoving = stopMoving;

    const dimensions = getGameDimension();
    this._bg = generateBGSprite(dimensions.width, 40);
    this.addChild(this._bg);

    this._fire = new Sprite(pixi.utils.TextureCache['tc-fire.png']);
    this._fire.pivot.x = this._fire.width / 2;
    this._fire.x = this._bg.width / 2;

    this.interactiveChildren = true;
    this._left = new Sprite(pixi.utils.TextureCache['tc-left.png']);
    this._left.x = 0;

    this._right = new Sprite(pixi.utils.TextureCache['tc-right.png']);
    this._right.x = this._bg.width - this._right.width;

    this.addChild(this._left);
    this.addChild(this._fire);
    this.addChild(this._right);

    this.on('added', this.addListeners);
    this.on('removed', this.removeListeners);
  }

  public onTouchFire = () => {
    this._fire.alpha = 0.7;
    this._fire.tint = 0xff3300;
    if (typeof this.blastEm === 'function') {
      // this.stopMoving();
      this.blastEm();
    }
  };

  private onFireUp = () => {
    this._fire.alpha = 1;
    this._fire.tint = 0xffffff;
  };

  private onMoveLeft = (e: MouseEvent): void => {
    const el = (e.currentTarget as unknown) as Sprite;
    el.alpha = 0.7;
    el.tint = 0xff3300;
    el.x = el.x + 1;
    el.y = el.y + 1;
    this.moveLeft();
  };

  private onMoveRight = (e: MouseEvent): void => {
    const el = (e.currentTarget as unknown) as Sprite;
    el.alpha = 0.7;
    el.tint = 0xff3300;
    el.x = el.x + 1;
    el.y = el.y + 1;
    this.moveRight();
  };

  private onStopMove = (e: MouseEvent): void => {
    const el = (e.currentTarget as unknown) as Sprite;
    el.alpha = 1;
    el.tint = 0xffffff;
    el.x = el.x - 1;
    el.y = el.y - 1;
    this.stopMoving();
  };

  private addListeners = () => {
    this._left.interactive = this._fire.interactive = this._right.interactive = true;
    this._left.on('touchstart', this.onMoveLeft);
    this._left.on('touchend', this.onStopMove);
    this._left.on('touchendoutside', this.onStopMove);
    this._right.on('touchstart', this.onMoveRight);
    this._right.on('touchend', this.onStopMove);
    this._right.on('touchendoutside', this.onStopMove);
    this._fire.on('touchstart', this.onTouchFire);
    this._fire.on('touchend', this.onFireUp);
    this._fire.on('touchendoutside', this.onFireUp);
  };

  private removeListeners = () => {
    this._left.off('touchstart', this.moveLeft);
    this._left.off('touchend', this.stopMoving);
    this._left.off('touchendoutside', this.stopMoving);
    this._right.off('touchstart', this.moveRight);
    this._right.off('touchend', this.stopMoving);
    this._right.off('touchendoutside', this.stopMoving);
    this._fire.off('touchstart', this.onTouchFire);
    this._fire.off('touchend', this.onFireUp);
    this._fire.off('touchendoutside', this.onFireUp);
  };
}

export default TouchControls;
