import { Container, Sprite, Text } from 'pixi.js';
import { generateBGSprite } from './Starfield';
import { GAME_DIMENSIONS } from '../index';
import Scorekeeper from './Scorekeeper';
import StatDisplay from './StatDisplay';
import amplitudeClient from '../../lib/amplitude';
import pixi from '../../lib/pixi';

class GameOver extends Container {
  private _bgLayer: Sprite;
  private _gameOver: Text;
  private scorekeeper: typeof Scorekeeper;
  private _onRestartGame: any;
  constructor(
    onRestartGame: any = () => console.log('restart game'),
    width: number = GAME_DIMENSIONS.width,
    height: number = GAME_DIMENSIONS.height
  ) {
    super();
    this.scorekeeper = Scorekeeper;
    this._onRestartGame = onRestartGame;
    this._bgLayer = generateBGSprite(width, height);

    const finalStats = this.scorekeeper.getFinalStats();
    amplitudeClient.logEvent('GAME:COMPLETE', finalStats);
    // console.log(finalStats);
    this.addChild(this._bgLayer);

    this._gameOver = new Text('GAME OVER', {
      fontFamily: 'Bebas Neue',
      fontSize: pixi.utils.isMobile.any ? 55 : 64,
      fill: 0xff3300,
    });

    const playAgain = new Text('PLAY AGAIN', {
      fontFamily: 'Bebas Neue',
      fontSize: 34,
      fill: 0x444444,
    });

    playAgain.interactive = true;

    this._gameOver.pivot.x = this._gameOver.width / 2;
    this._gameOver.pivot.y = this._gameOver.height / 2;
    this._gameOver.x = this._bgLayer.width / 2;
    this._gameOver.y = pixi.utils.isMobile.any ? 100 : 200;

    const textTotalWidth = pixi.utils.isMobile.any
      ? window.innerWidth - 100
      : 420;

    const finalStatsContainer = new Container();

    const yourScore = new StatDisplay(
      'Your Score',
      `${new Intl.NumberFormat().format(finalStats.points)}`,
      textTotalWidth
    );

    const statsSharted = new StatDisplay(
      'Enemies Sharted',
      `${finalStats.totalKills}/${finalStats.enemiesDropped}`,
      textTotalWidth
    );

    statsSharted.y = yourScore.height + 10;

    const statsShartLethality = new StatDisplay(
      'Shart Lethality',
      `${finalStats.lethality}`,
      textTotalWidth
    );
    statsShartLethality.y = statsSharted.y + statsSharted.height + 10;

    statsShartLethality.y = statsSharted.y + statsSharted.height + 10;

    const statShartsFired = new StatDisplay(
      'Shart Hits / Fired',
      `${finalStats.shotsHit}/${finalStats.shotsFired}`,
      textTotalWidth
    );

    statShartsFired.y = statsShartLethality.y + statsShartLethality.height + 10;

    const statsShartAccuracy = new StatDisplay(
      'Shart Accuracy',
      `${finalStats.shotAccuracy}`,
      textTotalWidth
    );
    statsShartAccuracy.y = statShartsFired.y + statShartsFired.height + 10;

    const statsTotalTime = new StatDisplay(
      'Game Duration',
      finalStats.gameDuration,
      textTotalWidth
    );

    statsTotalTime.y = statsShartAccuracy.y + statsShartAccuracy.height + 10;

    finalStatsContainer.addChild(yourScore);
    finalStatsContainer.addChild(statsSharted);
    finalStatsContainer.addChild(statsShartLethality);
    finalStatsContainer.addChild(statShartsFired);
    finalStatsContainer.addChild(statsShartAccuracy);
    finalStatsContainer.addChild(statsTotalTime);

    finalStatsContainer.y = 270;
    finalStatsContainer.x =
      this._bgLayer.width / 2 - finalStatsContainer.width / 2;

    this.addChild(this._gameOver);
    this.addChild(finalStatsContainer);

    playAgain.y = finalStatsContainer.y + finalStatsContainer.height + 40;
    playAgain.x = this._bgLayer.width / 2 - playAgain.width / 2;

    playAgain.on(pixi.utils.isMobile.any ? 'touchend' : 'click', () => {
      amplitudeClient.logEvent('GAME:RESTART-CLICK');
      this._onRestartGame();
    });
    playAgain.on('mouseover', () => {
      playAgain.style.fill = 0x00aeff;
    });

    playAgain.on('focus', () => {
      playAgain.style.fill = 0xff3300;
    });

    playAgain.on('blur', () => {
      playAgain.style.fill = 0x444444;
    });

    playAgain.on('mouseout', () => {
      playAgain.style.fill = 0x444444;
    });

    this.addChild(playAgain);
  }
}

export default GameOver;
