import React from 'react';

export interface IModalState {
  element: React.ReactNode | null;
}
export const initialState: IModalState = {
  element: null,
};
export enum MODAL_ACTION {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

export interface IModalAction {
  type: MODAL_ACTION;
  element?: React.ReactNode | null;
}

export const modalReducer = (
  state: IModalState = { ...initialState },
  action: IModalAction,
): IModalState => {
  switch (action.type) {
    case MODAL_ACTION.SHOW:
      return { ...state, element: action.element };

    case MODAL_ACTION.HIDE:
      return { ...state, element: null };
  }

  return state;
};
