import pixi from '../lib/pixi';
import gsap from '../lib/gsap';

import SpaceButtBlaster from './SpaceButtBlaster';
import StartScreen from './StartScreen';
import { DisplayObject } from 'pixi.js';
import BackgroundManager from './elements/BackgroundManager';
import throttle from 'lodash/throttle';

import amplitudeClient from '../lib/amplitude';

const handleResize = () => {
  amplitudeClient.logEvent('WINDOW:RESIZED');
  if (game) {
    centerGame(game);
  }

  const centerX = window.innerWidth / 2;
  const centerY = window.innerHeight / 2;

  if (startScreen) {
    startScreen.position.set(centerX, centerY);
  }
};

window.onresize = throttle(handleResize, 100);

export const testApp = new pixi.Application({
  resizeTo: window,
});

let game: any = undefined;
let bgManager: BackgroundManager | undefined = undefined;
let startScreen: StartScreen | undefined = undefined;

testApp.ticker.stop();
gsap.ticker.add(() => {
  testApp.ticker.update();
});

const centerGame = (el: SpaceButtBlaster | StartScreen) => {
  const dimensions = getGameDimension();

  const gameVerticalOffset = pixi.utils.isMobile.any ? 30 : 0;

  el.x = Math.round(testApp.renderer.width / 2 - dimensions.width / 2);
  el.y =
    Math.round(testApp.renderer.height / 2 - dimensions.height / 2) -
    gameVerticalOffset;

  if (instructions) {
    instructions.x = Math.round(window.innerWidth / 2 - instructions.width / 2);
    instructions.y = Math.round(
      window.innerHeight / 2 + dimensions.height / 2 + 40
    );
  }
};

export const GAME_DIMENSIONS = {
  width: 520,
  height: 590,
};

export const getGameDimension = (): { width: number; height: number } => {
  const dimensions = { ...GAME_DIMENSIONS };
  if (pixi.utils.isMobile.any) {
    // TODO: force vertical orientation
    dimensions.width = window.innerWidth * 0.95;
    dimensions.height = window.innerHeight - 150;
  }
  return dimensions;
};

// let isGameStarted: boolean = false;
//
// /**
//  * Load up the assets, load up the bus
//  * @param container
//  * @param eventBus
//  */
//
let instructions: any = undefined;
const createGame = (container: HTMLDivElement, eventBus: any) => {
  const dimensions = getGameDimension();
  const spaceButt = new SpaceButtBlaster(
    eventBus,
    (bgManager as unknown) as typeof BackgroundManager,
    dimensions.width,
    dimensions.height
  );
  game = spaceButt;

  if (!pixi.utils.isMobile.any) {
    instructions = new pixi.Text(
      '[S - D] and [K - L] move right to left\r[ spacebar ] fires poop corn',
      {
        align: 'center',
        fontFamily: 'Space Mono',
        fontSize: 11,
        fill: 0x777777,
        leading: 8,
      }
    );
    testApp.stage.addChild(instructions);
  }

  centerGame(game);
};

const onGameStartHandler = (container: HTMLDivElement, eventBus: any) => () => {
  testApp.stage.removeChild(startScreen as DisplayObject);
  createGame(container, eventBus);
  centerGame(game);
  game.alpha = 0;
  testApp.stage.addChild(game);
  gsap.to(game, { alpha: 1 }).duration(0.8);
  amplitudeClient.logEvent('GAME:STARTED');
};

const createStarfield = () => {
  const dimensions = getGameDimension();
  bgManager = new BackgroundManager(dimensions.width, dimensions.height);
};

export const initGame = (container: HTMLDivElement, eventBus: any) => {
  amplitudeClient.logEvent('GAME:WELCOME');
  createStarfield();
  testApp.stage.addChildAt(bgManager as DisplayObject, 0);
  const onGameStart = onGameStartHandler(container, eventBus);

  const dimensions = getGameDimension();

  // if (pixi.utils.isMobile.any) {
  //   new ScreenOrientation().lock('portrait');
  // }

  startScreen = new StartScreen(
    dimensions.width,
    dimensions.height,
    onGameStart,
    eventBus
  );
  startScreen.x = window.innerWidth / 2;
  startScreen.y = window.innerHeight / 2;

  testApp.stage.addChild(startScreen);
  container.appendChild(testApp.view);
};

export const destroyApp = () => {
  console.log('reloady');
  testApp.stage.destroy({ children: true, texture: true, baseTexture: true });
  bgManager?.destroy({ children: true, texture: true, baseTexture: true });
  testApp.destroy(true);
};
