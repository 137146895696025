import { Container, Text } from 'pixi.js';
import { monospaceStyle } from './Scoreboard';

const labelStyle = {
  fontFamily: 'Bebas Neue',
  fontSize: 15,
  fill: 0x666666,
};

class StatDisplay extends Container {
  private label: Text;
  private value: Text;
  private totalWidth: number;

  constructor(labelText: string, valueText: string, totalWidth: number = 420) {
    super();
    this.totalWidth = totalWidth;
    this.label = new Text(labelText, labelStyle);
    this.value = new Text(valueText, monospaceStyle);
    this.value.x = totalWidth - this.value.width;
    this.addChild(this.label);
    this.addChild(this.value);
  }
}

export default StatDisplay;
