import styled from 'styled-components';

export const StyledApp = styled.div`
  position: relative;
  min-height: 100vh;

  .font-1 {
    font-family: 'Bebas Neue', sans-serif;
  }

  .app-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: #333333;
    z-index: 1;
  }

  .game-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .directions {
    font-family: Monaco, monospace;
    text-align: center;
    color: #3f515d;
    font-size: 10px;
    z-index: 10;
    padding-top: 8px;
  }

  .counter {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(27, 12, 78, 0.2);
    width: 100%;
    margin-bottom: 20px;
    z-index: 5;
    color: white;
    font-size: 16px;
    text-align: center;
    padding: 20px;
    color: #999;
  }

  .pop {
    color: #00aeff;
  }

  .chill {
    color: #718d99;
    margin-left: 10px;
  }
`;
