import { Container } from 'pixi.js';
import Starfield from './Starfield';
import { GAME_DIMENSIONS } from '../index';
import TrippyBackground from './backgrounds/Trippy';
import ThemeManager from './ThemeManager';
class BackgroundManager extends Container {
  private starField: Starfield = new Starfield();
  private themeManager: any;
  private _gameWidth: number;
  private _gameHeight: number;

  private currentBG: any = undefined;

  constructor(
    w: number = GAME_DIMENSIONS.width,
    h: number = GAME_DIMENSIONS.height
  ) {
    super();
    this._gameWidth = w;
    this._gameHeight = h;

    this.themeManager = ThemeManager;
    this.themeManager.on('UPDATE', this.handleThemeUpdate);
    this.addChild(this.starField);
  }

  public resetBackground = () => {
    if (
      this.currentBG &&
      this.currentBG.transitionOut
    ) {
      console.log('tell the currentBG to transition out');
      this.currentBG.transitionOut();
    }
  };

  private onTransitionIn = () => {
    this.starField.pause();
  };

  private onTransitionOut = () => {
    console.log('transition out complete');
    if (this.currentBG) {
      this.removeChild(this.currentBG);
      this.currentBG = null;
    }
    this.themeManager.theme = 'default';
    this.starField.resume();
  };

  private handleThemeUpdate = (data: any) => {
    if (data.theme === 'tripout') {
      this.currentBG = new TrippyBackground(
        this.onTransitionIn,
        this.onTransitionOut
      );
      this.addChild(this.currentBG);
    }
  };
}
export default BackgroundManager;
