import pixi from '../../lib/pixi';
import gsap from '../../lib/gsap';
import { EventEmitter } from 'events';

class CornNugget extends EventEmitter {
  private _view: any;
  private _movementY: number = 12;
  private _shotDuration: number = 4;

  get movementY() {
    return this._movementY;
  }

  constructor(
    texture: any,
    width: number,
    height: number,
    x: number = 0,
    y: number = 0,
    shotDuration: number = 4
  ) {
    super();
    this._view = new pixi.Sprite(texture);
    this._view.width = width;
    this._view.height = height;
    this._view.x = x;
    this._view.y = y;
    this._shotDuration = shotDuration;
    gsap.to(this, { _movementY: 0 }).duration(this._shotDuration);
  }

  get view() {
    return this._view;
  }
}

export default CornNugget;
