import React from 'react';
import { ModalProvider } from './Modal';

interface IAppContextProps {}

export const AppContext: React.FC<IAppContextProps> = props => {
  return <ModalProvider>{props.children}</ModalProvider>;
};

export default AppContext;
