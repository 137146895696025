import styled from 'styled-components';

export const HowToPlayStyles = styled.div`
  margin: auto;
  margin-top: 40px;
  margin-bottom: 120px;
  max-width: 640px;
  width: 80%;
  padding: 20px 30px;
  background-color: white;
  border-radius: 12px;

  .info {
    font-family: 'Space Mono', sans-serif;
    font-size: 14px;
    color: #777;
  }

  .heading {
    display: block;
    font-size: 26px;
    font-family: 'Bebas Neue', sans-serif;
    color: #665353;
    line-height: 1;
    margin-bottom: 15px;
  }

  .subheading {
    display: block;
    font-size: 16px;
    font-family: 'Bebas Neue', sans-serif;
    color: #675353;
    line-height: 1;
    margin-bottom: 3px;
    padding-top: 20px;
  }

  .drop-label {
    font-size: 16px;
    font-family: 'Bebas Neue', sans-serif;
    color: #999;
    margin-bottom: 5px;
  }

  .drop-info {
    display: flex;
    margin-bottom: 10px;

    .image {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .txt {
      flex-grow: 2;
      margin-left: 20px;
    }
  }
`;
