import { Sprite } from 'pixi.js';

export const checkHits = (sprite1: Sprite, sprite2: Sprite): boolean =>
  hitTest(
    sprite1.position.x,
    sprite1.position.y,
    sprite1.width,
    sprite1.height,
    sprite2.position.x,
    sprite2.position.y,
    sprite2.width,
    sprite2.height
  );

const hitTest = (
  x1: number,
  y1: number,
  w1: number,
  h1: number,
  x2: number,
  y2: number,
  w2: number,
  h2: number
): boolean => {
  if (x1 + w1 > x2)
    if (x1 < x2 + w2) if (y1 + h1 > y2) if (y1 < y2 + h2) return true;
  return false;
};
