import { Sprite, Texture } from 'pixi.js';

export type SpecialType = 'taco' | 'hotdog' | 'broccoli' | 'wing' | 'shroom';

export type SpecialTextures = { [key in SpecialType]?: Texture };
type MoveSpeeds = { [key in SpecialType]: number };
const moveSpeeds: MoveSpeeds = {
  taco: 0.5,
  hotdog: 0.7,
  broccoli: 0.9,
  wing: 1.1,
  shroom: 1.3,
};
const durations: MoveSpeeds = {
  taco: 2000,
  hotdog: 4000,
  broccoli: 5000,
  wing: 7000,
  shroom: 6000,
};

class SpecialDrop extends Sprite {
  private _moveSpeed: number = 0.2;
  get moveSpeed() {
    return this._moveSpeed as number;
  }
  set moveSpeed(v: number) {
    this._moveSpeed = v;
  }
  private _type: SpecialType;
  get type() {
    return this._type;
  }
  set type(v: SpecialType) {
    this._type = v;
  }
  private _duration: number = 2000;
  get duration() {
    return this._duration;
  }
  private _textures: SpecialTextures;
  constructor(type: SpecialType, textures: SpecialTextures) {
    super(textures[type]);
    this._type = type;
    this._textures = textures;
    this._moveSpeed = moveSpeeds[type];
    this._duration = durations[type];
    this.height = this.width = 30;
  }
}

export default SpecialDrop;
