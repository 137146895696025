import React from 'react';
import { HowToPlayStyles } from './Styles';
import { useModalDispatch } from '../context/Modal';

interface IHowToPlayProps {}

export const HowToPlay: React.FC<IHowToPlayProps> = props => {
  const modalDispatch = useModalDispatch();
  const onCloseModal = () => {
    modalDispatch({ type: 'HIDE' });
  };
  return (
    <HowToPlayStyles>
      <h1 className="heading">What is this 💩 game and how do i play it?</h1>
      <div className="info">
        <p>
          During the Great Garbage Wars of 2108, the galaxy faces a new threat
          from an interdimensional species whose prime directive is to flood our
          solar system with inferior quality bootleg merchandise.
        </p>
        <p>
          Facing this threat, humanity finally banded together and put all the
          stupid 💩 aside. A scientific process was developed to turn human
          waste into sentient organic matter, and a new line of defense was
          created to safeguard our galaxy.
        </p>
        <p>Poop rockets.</p>
        <p>
          You remotely pilot a state of the art, 93 million dollar, extremely
          lethal...poop rocket. Literally the smartest 💩 in the galaxy.
        </p>
        <h3 className={'subheading'}>Actual Instructions</h3>
        <p>
          Using your antique qwerty keyboard, you are able to control your poop
          rocket's horizontal movement through the browser galaxy.
        </p>
        <p>
          The <b>[S - D]</b> keys move the ship left and right. The{' '}
          <b>[K - L]</b> keys also move the ship left and right, because all
          futuristic space 💩 needs redundancy in controls.
        </p>
        <p>
          The <b>[spacebar]</b> fires your weapon.
        </p>
        <h3 className={'subheading'}>Scoring And Rewards</h3>
        <p>
          Shooting stuff gives you points. The more stuff you shoot, the more
          points you get.
        </p>
        <p>
          Shooting the main dude launching bootlegs at you causes them to drop
          random food items that enhance your naturally gassy abilities.
        </p>
        <div className="drop-label">Taco Toots</div>
        <div className="drop-info">
          <div className="image">
            <img src={'images/taco.png'} alt={'Taco Farts Bruh'} />
          </div>
          <div className="txt">
            The taco powers up your ability to drop noxious taco-powered fart
            bombs that will stop enemies in their tracks. Use the opportunity to
            light em up.
          </div>
        </div>

        <div className="drop-label">Hot Dog Blasts</div>
        <div className="drop-info">
          <div className="image">
            <img src={'images/hot-dog.png'} alt={'Hot Doggy Blasts'} />
          </div>
          <div className="txt">
            The hot dog blast is twice as deadly as the taco toot, and will wipe
            out half of the field who can't cut the mustard. To be frank, you
            should relish every opportunity to catch up with a hot dog when it
            drops!
          </div>
        </div>

        <div className="drop-label">Broccoli Bombs</div>
        <div className="drop-info">
          <div className="image">
            <img src={'images/broccoli.png'} alt={'Broccoli Bombs'} />
          </div>
          <div className="txt">
            When it comes to natural gas explosions, there is no more lethal
            accellerant than the broccoli bomb. Catch one of these, and everyone
            gets it. Scorched earth in outer space. Sometimes it just be like
            that.
          </div>
        </div>

        <div className="drop-label">Atomic Wangs</div>
        <div className="drop-info">
          <div className="image">
            <img src={'images/wing.png'} alt={'Hot Wang'} />
          </div>
          <div className="txt">
            While the atomic wing doesn't actually directly affect your enemies
            in the way other gas enhancing items may, the effect is still
            deadly. Catching a wing will turn your poop rocket into a red hot
            lava blasting death machine. The blasts from an atomic hot wang
            powered poop rocket do not care about your simple human physics,
            they can and will cut through everything!
          </div>
        </div>
      </div>
      <div style={{ padding: 40, textAlign: 'center' }}>
        <button onClick={onCloseModal}>Close this hot mess</button>
      </div>
    </HowToPlayStyles>
  );
};

export default HowToPlay;
