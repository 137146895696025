import { EventEmitter } from 'events';

const POINT_VALUES = {
  enemy: 750,
  enemyFlipped: -100,
  specialsDropped: 100,
  specialsCaught: 250,
};

export const roundTo = (num: number, place: 10 | 100 | 1000 | 10000): number =>
  Math.round(num * place) / place;

const pad = (n: number, z: number = 2): string => ('00' + n).slice(-z);

export const msToTime = (s: number): string => {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;
  const minsSeconds = pad(mins) + ':' + pad(secs);
  return hrs > 0 ? pad(hrs) + ':' + minsSeconds : minsSeconds;
};

export const msToTimeString = (ms: number): string => {
  const seconds = Math.floor(ms / 1000) % 60;
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

  let timeString = `${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
  if (minutes > 0)
    timeString = `${minutes} ${
      minutes === 1 ? 'minute' : 'minutes'
    } ${timeString}`;
  if (hours > 0)
    timeString = `${hours} ${hours === 1 ? 'hour' : 'hours'} ${timeString}`;

  return timeString;
};

class Scorekeeper extends EventEmitter {
  private _totalPoints: number = 0;
  private _kills: number = 0;
  private _total: number = 0;
  private _enemiesFlipped: number = 0;
  private _specialsDropped: number = 0;
  private _specialsCaught: number = 0;
  private _gameStart: number = 0;
  private _gameEnd: number = 0;

  private _shotsFired: number = 0;
  private _shotsHit: number = 0;

  public logGameStart = () => {
    this._gameStart = performance.now();
  };

  public logGameEnd = () => {
    this._gameEnd = performance.now();
  };

  public reset = () => {
    this.totalPoints = 0;
    this.kills = 0;
    this.enemiesFlipped = 0;
    this.specialsDropped = 0;
    this.shotsFired = 0;
    this.shotsHit = 0;
    this.specialsCaught = 0;
    this._gameStart = 0;
    this._gameEnd = 0;
  };

  get enemiesFlipped() {
    return this._enemiesFlipped;
  }
  set enemiesFlipped(v: number) {
    this._enemiesFlipped = v;
    this.calculate();
  }

  get specialsDropped() {
    return this._specialsDropped;
  }
  set specialsDropped(v: number) {
    this._specialsDropped = v;
    this.calculate();
  }

  get specialsCaught() {
    return this._specialsCaught;
  }
  set specialsCaught(v: number) {
    this._specialsCaught = v;
    this.calculate();
  }

  get shotsFired() {
    return this._shotsFired;
  }
  set shotsFired(v: number) {
    this._shotsFired = v;
  }

  get shotsHit() {
    return this._shotsHit;
  }
  set shotsHit(v: number) {
    this._shotsHit = v;
  }

  get totalPoints() {
    return this._totalPoints;
  }
  set totalPoints(v: number) {
    this._totalPoints = v;
  }
  get kills() {
    return this._kills;
  }
  set kills(v: number) {
    this._kills = v;
    this.calculate();
  }
  get total() {
    return this._total;
  }
  set total(v: number) {
    this._total = v;
  }

  public getFinalStats = () => {
    const durationMS = this._gameEnd - this._gameStart;
    return {
      totalKills: this.kills,
      enemiesDropped: this.total,
      points: this.totalPoints,
      specialsDropped: this.specialsDropped,
      specialsCaught: this.specialsCaught,
      lethality: roundTo(this.kills / this.total, 100),
      shotsFired: this.shotsFired,
      shotsHit: this.shotsHit,
      shotAccuracy: roundTo(this.shotsHit / this.shotsFired, 100),
      gameDuration: msToTime(durationMS),
      durationMS,
    };
  };

  private calculate = () => {
    this.totalPoints =
      this.kills * POINT_VALUES.enemy +
      this.enemiesFlipped * POINT_VALUES.enemyFlipped +
      this.specialsCaught * POINT_VALUES.specialsCaught +
      this.specialsDropped * POINT_VALUES.specialsDropped;
    this.emit('SCORE:UPDATE', { points: this.totalPoints });
  };

  public trackEnemyKilled = () => {
    this.kills += 1;
  };

  public trackSpecialsDropped = () => {
    this.specialsDropped += 1;
  };

  public trackSpecialsCaught = () => {
    this.specialsCaught += 1;
  };

  public trackEnemiesFlipped = () => {
    this.enemiesFlipped += 1;
  };
}

export default new Scorekeeper();
