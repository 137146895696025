import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { StyledApp } from './Styles';
import { initGame } from './game';
import { EventBus } from './types';
import { useModalDispatch } from './components/context/Modal';
import HowToPlay from './components/HowToPlay';
import useLocalStorageState from 'use-local-storage-state';

function App() {
  const modalDispatch = useModalDispatch();
  const testAppShellRef = useRef<HTMLDivElement | null>(null);
  const [highScore, setHighScore] = useLocalStorageState('highScore', 0);
  const gameMounted = useRef<boolean>(false);

  const updateHighScore = useCallback(
    (score: number) => {
      console.log('previous high score is', highScore);
      if (score > highScore) {
        setHighScore(score);
      }
    },
    [highScore, setHighScore]
  );

  const gameEventBus = useMemo<EventBus>(
    () => (event: string, data: any = {}) => {
      switch (event) {
        case 'showInfo':
          modalDispatch({
            type: 'SHOW',
            element: <HowToPlay />,
          });
          break;

        case 'gameComplete':
          console.log('GAME COMPLETE', data);
          updateHighScore(data.score);
          break;
      }
    },
    [modalDispatch, updateHighScore]
  );

  const eventBusRef = useRef<EventBus>(gameEventBus);

  useEffect(
    () => {
      const shellRef = testAppShellRef;
      if (shellRef.current && !gameMounted.current) {
        initGame(shellRef.current, eventBusRef.current);
        gameMounted.current = true;
        // return () => {
        //   if (shellRef.current) {
        //     // console.log('does this leak?');
        //     // destroyApp();
        //     shellRef.current.innerHTML = '';
        //   }
        // };
      }
    },
    []
  );
  return (
    <StyledApp>
      <div className="app-wrapper">
        <div ref={testAppShellRef} className={'game-container'} />
      </div>
    </StyledApp>
  );
}

export default App;
