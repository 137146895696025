import { Container, Text } from 'pixi.js';
import Scorekeeper from './Scorekeeper';

export const monospaceStyle = {
  fontFamily: 'Space Mono',
  fontSize: 13,
  fill: 0x00aeff,
};

class Scoreboard extends Container {
  private txtScore: Text;
  private scorekeeper: any = Scorekeeper;

  constructor() {
    super();
    this.txtScore = new Text(`${this.scorekeeper.totalPoints}`, {
      ...monospaceStyle,
      align: 'right',
    });

    this.txtScore.x = 0;
    this.addChild(this.txtScore);
    this.scorekeeper.on('SCORE:UPDATE', () => {
      this.txtScore.text = `${new Intl.NumberFormat().format(
        this.scorekeeper.totalPoints
      )}`;
      this.positionText();
    });
  }

  private positionText = () => {
    this.txtScore.x = 0 - this.txtScore.width;
  };
}

export default Scoreboard;
